export const saneDateString = (date: Date) => {
  return date.getFullYear().toString() +
    '-' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    date.getDate().toString().padStart(2, '0');
};

const saneTimeString = (date: Date) => {
  return date.getHours().toString().padStart(2, '0') +
    ':' +
    date.getMinutes().toString().padStart(2, '0') +
    ':' +
    date.getSeconds().toString().padStart(2, '0');
};

export const saneDateTimeString = (date: Date) => {
  return `${saneDateString(date)} ${saneTimeString(date)}`;
};

export const saneShortDateTimeString = (date: Date, now: Date) => {
  if (date.toDateString() === now.toDateString())
    return saneTimeString(date);
  return saneDateTimeString(date);
};

interface DateTimeFormatOptions {
  // Ignored, always shown
  year?: "numeric";
  // Ignored, always shown with 2-digit numeric month
  month: "short" | "long";
  // May be hidden, and if hidden, hour/minute/second are hidden too
  day?: "2-digit";
  // hour/minute/second are always shown or hidden together
  hour?: "2-digit";
  minute?: "2-digit";
  // Missing means local time
  timeZone?: "UTC";
}

// Example:
// import { intlFixes } from 'mastodon/components/intl_fixes';
// const s: string = intlFixes.formatDate(orig, { year: 'numeric' });
export const intlFixes = {
  formatDate: (value: string | Date, format: DateTimeFormatOptions) => {
    // UTC is only used in mastodon/components/admin/Retention.jsx
    const date = new Date(value);
    const ex = format.timeZone === 'UTC' ? [
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    ] as const : [
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ] as const;

    const monthString = ex[0].toString() +
      '-' +
      (ex[1] + 1).toString().padStart(2, '0');
    if (!format.day)
      return monthString;

    const dayString = ex[0].toString() +
      '-' +
      (ex[1] + 1).toString().padStart(2, '0') +
      '-' +
      ex[2].toString().padStart(2, '0');
    if (!format.hour && !format.minute)
      return dayString;

    return dayString + ' ' +
      ex[3].toString().padStart(2, '0') +
      ':' +
      ex[4].toString().padStart(2, '0') +
      ':' +
      ex[5].toString().padStart(2, '0');
  },
};

interface FormattedDateProps {
  value: Date | string;
}

// Replaces FormattedDate from react-intl
export const FormattedDate = (props: FormattedDateProps & DateTimeFormatOptions) => {
  const date = new Date(props.value);
  const display = intlFixes.formatDate(date, props);
  return <time dateTime={date.toISOString()}>{display}</time>;
};
